<template>
    <div class="container">
        <div class="card m-5">
            <div class="card-body">
                <h1 class="text-center text-primary mt-4">
                    <strong>Maaf, Tidak Dizinkan Mengakses Halaman Ini</strong>
                </h1>
                <div class="text-center w-100 mt-3">
                    <b-btn size="lg" variant="outline-primary" href="#/"><font-awesome-icon icon="chevron-left" /> Kembali</b-btn>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { goBack } from '@/_helpers'

export default {
    methods: {
        goBack () {
            goBack(this)
        }
    },
}
</script>
